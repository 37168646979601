import mainCss from '../../../css/main.module.css'
import styled from '@emotion/styled';
import main_img_5 from '../../../css/images/main_img_5.png';
import img_icon_1 from '../../../css/images/img_icon_1.png';
import img_icon_2 from '../../../css/images/img_icon_2.png';
import img_icon_3 from '../../../css/images/Img_icon_3.png';
import text_icon from '../../../css/images/text_icon.png';
import text_last from '../../../css/images/text_icon_img_last.png';
import URL from '../../constants/url';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useRef, useState } from 'react';

const Main3Img = ({setTargetY3, allLoaded}) => {

    const onClickBtn = () => {
        let pageUrl = URL.GOOGLE_APP;
        //let pageUrl = URL.MAIN;
        window.location.href = pageUrl;
    }

    const isMediumDevice2 = useMediaQuery( {query : "(min-width : 701px)"});

    const targetRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setTargetY3(targetRef.current.offsetTop);
        }, 500)
    }, []);
    

    // 화면 넓이를 저장할 상태 변수
    const [width, setWidth] = useState(window.innerWidth);

    // 화면 넓이 변경을 감지하는 함수
    const handleResize = () => {
        setWidth(window.innerWidth);  // 현재 창의 넓이로 상태 업데이트
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);  // resize 이벤트 리스너 등록

        return () => {
        window.removeEventListener('resize', handleResize);  // 컴포넌트 언마운트 시 리스너 제거
        };
    }, []);  // 빈 의존성 배열로 마운트 시 한 번만 실행

    useEffect(() => {
        if (allLoaded) {
            setTargetY3(targetRef.current.offsetTop);
        }
    }, [allLoaded])
    useEffect(() => {
        if (allLoaded){
            setTargetY3(targetRef.current.offsetTop);
        }
    }, [width])

    return (
        <>
            <ContainerDiv className={mainCss.container + " container"} ref={targetRef}>
                <TextImg1 src={main_img_5}/>
                <ColBoxDiv>
                    <ColDiv>
                        <img src={img_icon_1} style={{marginLeft : '30px', marginTop : '-10px'}}/>
                        <TitleBox >
                            <TitleText>베이직 플랜</TitleText>
                        </TitleBox>
                        <PriceTabDiv style={{marginTop : '90px'}}>
                            <MoneySpan>50,000원<WonSpan >/월</WonSpan></MoneySpan>
                            <PriceTabP style={{fontWeight : '500', fontSize : '18px', position : 'relative', top : '-5px', marginBottom : '25px'}}>1개월 이용권</PriceTabP>
                        </PriceTabDiv>
                        <BtnDiv onClick={onClickBtn}>
                            <PriceTabP style={{fontWeight : '700', fontSize : '18px', color : '#fff', position : 'relative', top : '17px'}}>바로 시작하기</PriceTabP>
                        </BtnDiv>
                    </ColDiv>
                    {/*<ColDiv >
                        <img src={img_icon_3} style={{marginLeft : '30px', marginTop : '-10px'}}/>
                        <TitleBox >
                            <TitleText>베이직 플러스 플랜</TitleText>
                        </TitleBox> 
                        <PriceTabDiv>
                            <PriceTabP><del>50,000원</del></PriceTabP>
                            <MoneySpan>45,000원</MoneySpan>
                            <PriceTabP style={{fontWeight : '500', fontSize : '18px', position : 'relative', top : '-5px', marginBottom : '25px'}}>월마다 정기 결제</PriceTabP>
                        </PriceTabDiv>
                        <BtnDiv onClick={onClickBtn}>
                            <PriceTabP style={{fontWeight : '700', fontSize : '18px', color : '#fff', position : 'relative', top : '17px'}}>바로 시작하기</PriceTabP>
                        </BtnDiv>
                    </ColDiv>*/}
                    <ColDiv >
                        <img src={img_icon_2} style={{marginLeft : '30px', marginTop : '-10px'}}/>
                        <TitleBox >
                            <TitleText>프리미엄 플랜</TitleText>
                        </TitleBox>
                        <PriceTabDiv>
                            <PriceTabP><del>600,000원</del></PriceTabP>
                            <MoneySpan>450,000원</MoneySpan>
                            <PriceTabP style={{fontWeight : '500', fontSize : '18px', position : 'relative', top : '-5px', marginBottom : '25px'}}>12개월 이용권</PriceTabP>
                        </PriceTabDiv>
                        <BtnDiv onClick={onClickBtn}>
                            <PriceTabP style={{fontWeight : '700', fontSize : '18px', color : '#fff', position : 'relative', top : '17px'}}>바로 시작하기</PriceTabP>
                        </BtnDiv>
                    </ColDiv>
                </ColBoxDiv>
                <div style={{width : '100vw', height : '99px'}}></div>
                {isMediumDevice2 === true ? 
                <TextBoxDiv>
                    <TextImg src={text_icon}/> 
                </TextBoxDiv>: <TextImg2 src={text_last}/>}
                <div style={{width : '100vw', height : '75px'}}></div>
            </ContainerDiv>
        </>
    );
};
export default Main3Img;

const TextImg = styled.img`
    margin: auto;
    align-items: center;
    @media (max-width: 992px) {
        width: 70vw;
    }
`;

const TextImg2 = styled.img`
    width: 400px;
    height: auto;
    margin: auto;
    align-items: center;
`;

const TextBoxDiv = styled.div`
    width: 820px;
    height: 118px;
    border-radius: 10px;
    background-color: #555960;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    align-items : center;
    @media (max-width: 992px) {
        width: 80vw;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
`;

/** div 태그 .priceTab css */
const PriceTabDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: center;
    margin-top: 54px;
`;

/** p 태그 .priceTab p css */
const PriceTabP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 24px;
    font-family: "Pretendard";
    color: #868D93;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
`;

/** span 태그 .money css */
const MoneySpan = styled.span`
    margin: 0;
    padding: 0;
    word-break: keep-all;
    font-size: 42px;
    font-family: "Pretendard";
    color: #222222;
    letter-spacing: -0.3px;
    font-weight: 700;
    position: relative;
    top: -10px;
    text-align: center;
`;

/** span 태그 .won css */
const WonSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 18px;
    font-family: "Pretendard";
    color: #868D93;
    letter-spacing: -0.3px;
    text-align: center;
    font-weight: 700;
`;

/** div 태그 .container css */
const ContainerDiv = styled.div`
    width: 100vw;
    height: auto;
    margin: 0px 0px;
    padding: 0px 0px;
    max-width: 1900px;
    background: #BDE4FE;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items : center;
`;

const TextImg1 = styled.img`
    width: 46.1vw;
    height: auto;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 128px;
    margin-bottom: 80px;
    @media (max-width: 992px) {
        min-width: 500px;
    }
`;

const ColBoxDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    justify-content: center;
    align-items : center;
`;

const ColDiv = styled.div`
    width: 400px;
    height: 400px;
    background-color: #fff;
    border-radius: 30px;
`;

const TitleBox = styled.div`
    justify-content: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    align-self: center;
    margin-top: -20px;
`;

const TitleText = styled.p`
    text-align: center;
    font-family: 'Pretendard';
    font-size: 24px;
    color: #222222;
    font-weight: 500;
`;

const BtnDiv = styled.div`
    width: 311px;
    height: 64px;
    border-radius: 10px;
    background-color: #31A2FF;
    margin: auto;
    cursor: pointer;
`;