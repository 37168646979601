import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

// 탑 버튼 컴포넌트
const TopButton = () => {

    const [showButton, setShowButton] = useState(false);

    // 탑 버튼 클릭
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }

    const showButtonClick = () => {
        if (window.scrollY > 300) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    }
    // 탑 버튼 생성 조건
    useEffect(() => {
        window.addEventListener("scroll", showButtonClick);
        return () => {
            window.addEventListener("scroll", showButtonClick);
        }
    }, [])

    return (
        <>
            { 
                showButton && <TopButtonButton type="button" onClick={scrollToTop}>
                    <Link className="fa-solid fa-arrow-up" style={{color: "#ffffff", textDecoration: "none"}}>TOP</Link>
                </TopButtonButton>
            }
        </>
    )

}

export default TopButton;

/** div 태그 */
const TopButtonButton = styled.button`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    position: relative;
    display: block;
    color: #22222a;
    text-decoration: none;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #22222a;
    background-color : #5A5A5A;
    opacity: 0.2;
    :hover {
        background: #ff5f37;
        color: #fff;
        border: 1px solid #ff5f37;
    };
    padding: 8px 16px;
    font-size: 14px;
    position: fixed;
    bottom: 30px;
    right: 30px;
`;