import MainImg1 from "./MainImg1";
import MainImg2 from "./MainImg2";



const MainComponentMobile = ({setTargetY1, setTargetY2, setTargetY3, handleImageLoad, allLoaded}) => {

    return (
        <>
            <MainImg1 setTargetY1={setTargetY1} setTargetY2={setTargetY2} setTargetY3={setTargetY3} handleImageLoad={handleImageLoad} allLoaded={allLoaded}/>
            <MainImg2 setTargetY3={setTargetY3} handleImageLoad={handleImageLoad} allLoaded={allLoaded}/>
        </>
    );
};
export default MainComponentMobile;