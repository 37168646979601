import { BrowserView, MobileView } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import TenSecondsBrowser from "./TenSecondsBrowser";
import TenSecondsMobile from "./TenSecondsMobile";
import 'bootstrap/dist/css/bootstrap.min.css';

const TenSecondsMain = () => {

    const isMediumDevice = useMediaQuery( {query : "(min-width : 361px)"});
    const isMediumDevice2 = useMediaQuery( {query : "(min-width : 701px)"});

    return (
        <>
            <BrowserView>
                {isMediumDevice === true ? <TenSecondsBrowser/> : <TenSecondsMobile/> }
            </BrowserView>
            <MobileView>
                {isMediumDevice2 === true ? <TenSecondsBrowser/> : <TenSecondsMobile/> }
            </MobileView>
        </>
    );
}

export default TenSecondsMain;