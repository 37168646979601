import mainCss from '../../../css/main.module.css'
import main1Img from '../../../css/images/main_1_img.png'
import styled from '@emotion/styled';

const Mian1Img = ({handleImageLoad}) => {

    return (
        <>
            <ContainerDiv className={mainCss.container + " container"}>
                <MainItemImg src={main1Img} onLoad={handleImageLoad}/>
            </ContainerDiv>
        </>
    );
};
export default Mian1Img;

/** div 태그 .container css */
const ContainerDiv = styled.div`
    width: 100vw;
    margin: 0px 0px;
    padding: 0px 0px;
`;

/** img 태그 .carousel-inner .carousel-item > img css */
const MainItemImg = styled.img`
    width: 100vw;
    height: -webkit-fill-available;
`;