import mainCss from '../../../css/main.module.css';
import main1Img from '../../../css/images/main_mobile_img_1.png';
import main2Img from '../../../css/images/main_mobile_img_2.png';
import app_download_btn from '../../../css/images/app_download_btn.png';
import main3Img from '../../../css/images/main_mobile_img_3.png';
import main4Img from '../../../css/images/main_mobile_img_4.png';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import URL from '../../constants/url';


const MainImg1 = ({setTargetY1, setTargetY2, setTargetY3, handleImageLoad, allLoaded}) => {

    const detectDevice = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        // iOS 검출
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }
        // Android 검출
        else if (/android/i.test(userAgent)) {
            return 'Android';
        }
    
        return 'unknown';
    }

    const [deviceType, setDeviceType] = useState(null);

    useEffect(() => {
        setDeviceType(detectDevice());
        setTimeout(() => {
            setTargetY1(target1Ref.current.offsetTop - 64);
            setTargetY2(target2Ref.current.offsetTop - 64);
            setTargetY3(target2Ref.current.offsetBottom - 64);
        }, 1000)
    }, []);

    const onClickDown = () => {
        if (deviceType !== null ) {
            if (deviceType === 'Android' || deviceType === 'unknown') {
                let pageUrl = URL.GOOGLE_APP;
                //let pageUrl = URL.MAIN;
                window.location.href = pageUrl;
            } else {
                let pageUrl = URL.APPLE_APP;
                //let pageUrl = URL.MAIN;
                window.location.href = pageUrl;
            }
        }
    }

    const target1Ref = useRef(null);
    const target2Ref = useRef(null);

    // 화면 넓이를 저장할 상태 변수
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // 화면 넓이 변경을 감지하는 함수
    const handleResize = () => {
        setWidth(window.innerWidth);  // 현재 창의 넓이로 상태 업데이트
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);  // resize 이벤트 리스너 등록

        return () => {
        window.removeEventListener('resize', handleResize);  // 컴포넌트 언마운트 시 리스너 제거
        };
    }, []);  // 빈 의존성 배열로 마운트 시 한 번만 실행

    useEffect(() => {
        if (allLoaded) {
            setTargetY1(target1Ref.current.offsetTop - 64);
            setTargetY2(target2Ref.current.offsetTop - 64);
            setTargetY3(target2Ref.current.offsetBottom - 64);
        }
    }, [allLoaded]);
    

    const [loaded1, setLoaded1] = useState(false);
    const [loaded2, setLoaded2] = useState(false);
    const [loaded3, setLoaded3] = useState(false);
    const [loaded4, setLoaded4] = useState(false);
    const [loaded5, setLoaded5] = useState(false);
    useEffect(() => {
        setTargetY1(target1Ref.current.offsetTop - 64);
        setTargetY2(target2Ref.current.offsetTop - 64);
        setTargetY3(target2Ref.current.offsetBottom - 64);
    }, [width, height, loaded1, loaded2, loaded3, loaded4, loaded5]);
    useEffect(() => {
        const img = new Image();
        img.src = main1Img;
        img.onload = () => {
          console.log('Image fully loaded!');
          setLoaded1(true);
        };
    }, []);
    useEffect(() => {
        const img = new Image();
        img.src = main2Img;
        img.onload = () => {
          console.log('Image fully loaded!');
          setLoaded2(true);
        };
    }, []);
    useEffect(() => {
        const img = new Image();
        img.src = app_download_btn;
        img.onload = () => {
          console.log('Image fully loaded!');
          setLoaded3(true);
        };
    }, []);
    useEffect(() => {
        const img = new Image();
        img.src = main3Img;
        img.onload = () => {
          console.log('Image fully loaded!');
          setLoaded4(true);
        };
    }, []);
    useEffect(() => {
        const img = new Image();
        img.src = main4Img;
        img.onload = () => {
          console.log('Image fully loaded!');
          setLoaded5(true);
        };
    }, []);


    return (
        <>
            <ContainerDiv className={mainCss.container + " container"}>
                <MainItemImg src={main1Img} onLoad={handleImageLoad}/>
                <MainItemImg2 src={main2Img} onLoad={handleImageLoad}/>
                <MainItemImg3 src={app_download_btn} onClick={onClickDown} onLoad={handleImageLoad}/>
                <MainItemImg4 src={main3Img} ref={target1Ref} onLoad={handleImageLoad}/>
            </ContainerDiv>
            <ContainerDiv2 ref={target2Ref}>
                <MainItemImg5 src={main4Img} onLoad={handleImageLoad}/>
            </ContainerDiv2>
        </>
    );
};
export default MainImg1;

/** img 태그 .carousel-inner .carousel-item > img css */
const MainItemImg = styled.img`
    width: 100vw;
    height: auto;
`;

/** div 태그 .container css */
const ContainerDiv = styled.div`
    width: 100vw;
    height: auto;
    margin: 0px 0px;
    padding: 0px 0px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 10%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 0.95) 40%,
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0.85) 75%,
        rgba(255, 255, 255, 0.8) 100%
    ), #009BFF;
    text-align: center;
`;

/** div 태그 .container css */
const ContainerDiv2 = styled.div`
    width: 100vw;
    height: auto;
    margin: 0px 0px;
    padding: 0px 0px;
    text-align: center;
`;

const MainItemImg2 = styled.img`
    width: auto;
    height: auto;
    margin: 0px 0px;
    padding: 0px 0px;
    margin-top: 71px;
    align-self: center;
`;

const MainItemImg3 = styled.img`
    margin: 0px 0px;
    padding: 0px 0px;
    margin-top: 10px;
    align-self: center;
`;

const MainItemImg4 = styled.img`
    width: 100vw;
    height: auto;
    margin: 0px 0px;
    padding: 0px 0px;
    margin-top: 80px;
    margin-bottom: 70px;
    align-self: center;
`;

const MainItemImg5 = styled.img`
    width: 100vw;
    height: auto;
    margin: 0px 0px;
    padding: 0px 0px;
    align-self: center;
`;