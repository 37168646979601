import { useEffect, useState } from "react";
import TopButton from "../common/TopButton";
import FooterBrowser from "./browserComponents/FooterBrowser";
import HeaderBrowser from "./browserComponents/HeaderBrowser";
import MainComponentBrowser from "./browserComponents/MainComponentBrowser";
import styled from '@emotion/styled';

const TenSecondsBrowser = () => {

    const [timersetting, setTimesetting] = useState(false);

    const start = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }

    useEffect(() => {
        start();
        setAllLoaded(false);
        setLoadedCount(0);
    }, []);

    const [targetY1, setTargetY1] = useState(2000);
    const [targetY2, setTargetY2] = useState(5167);
    const [targetY3, setTargetY3] = useState(6247);

    const [loadedCount, setLoadedCount] = useState(0);
    const [allLoaded, setAllLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoadedCount(prevCount => prevCount + 1);
    };

    useEffect(() => {
        if (loadedCount === 7) {
            setAllLoaded(true);
        }
    }, [loadedCount])

    const [lastScrollY, setLastScrollY] = useState(0);
    const [headerVisible, setHeaderVisible] = useState(true);
    const [scrollTimeout, setScrollTimeout] = useState(null);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        
        if (currentScrollY == targetY1 || currentScrollY == targetY2 || currentScrollY == targetY3) {
            setHeaderVisible(true);
        } else if (currentScrollY > lastScrollY) {
            // 스크롤을 내리고 있을 때
            setHeaderVisible(false);
        }  else {
            // 스크롤을 올리고 있을 때
            setHeaderVisible(true);
        }
        
        setLastScrollY(currentScrollY); // 현재 스크롤 위치를 저장
    };

    const handleScroll2 = () => {
        // 이미 설정된 타이머가 있다면 취소합니다.
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }

        // 새로운 타이머를 설정하여 일정 시간(예: 150 밀리초) 동안 추가 스크롤 이벤트가 없으면 스크롤 멈춤 이벤트를 발생시킵니다.
        const newTimeout = setTimeout(handleScroll, 500);
        setScrollTimeout(newTimeout);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
          if (scrollTimeout) {
            clearTimeout(scrollTimeout);
          }
        };
    }, [scrollTimeout]);

    useEffect(() => {
        if (scrollTimeout === null) {
            setHeaderVisible(true);
        }
    }, [scrollTimeout])

    return (
        <>
            <TopButton/>
            {
                headerVisible ? 
                <HeaderHeader>
                    <HeaderBrowser targetY1={targetY1} targetY2={targetY2} targetY3={targetY3} setHeaderVisible={setHeaderVisible}/>
                </HeaderHeader> : <></>
            }
            <div style={{width : "100vw", height : "72px", backgroundColor : "#0B68F3"}}></div>
            <MainComponentBrowser setTargetY1={setTargetY1} setTargetY2={setTargetY2} setTargetY3={setTargetY3} handleImageLoad={handleImageLoad} allLoaded={allLoaded} />
            <FooterBrowser/>
        </>
    )
};
export default TenSecondsBrowser;

const HeaderHeader = styled.header`
    position: fixed;
    top: 0;
    /* width: 100% */
    left: 0;
    right: 0;
    z-index: 1000;
`;