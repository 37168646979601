import Mian1Img from "./Main1Img";
import Main2Img from "./Main2Img";
import Main3Img from "./Main3Img";



const MainComponentBrowser = ({setTargetY1, setTargetY2, setTargetY3, handleImageLoad, allLoaded}) => {
    
    return (
        <>
            <Mian1Img handleImageLoad={handleImageLoad}/>
            <Main2Img setTargetY1={setTargetY1} setTargetY2={setTargetY2} handleImageLoad={handleImageLoad} allLoaded={allLoaded} />
            <Main3Img setTargetY3={setTargetY3} allLoaded={allLoaded}/>
        </>
    );
};
export default MainComponentBrowser;