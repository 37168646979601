import logoImg from '../../../css/images/Logo.png'
import headerCss from '../../../css/main_mobile.module.css'
import { Link } from 'react-router-dom';
import URL from '../../constants/url';
import styled from '@emotion/styled';
import menu_line from '../../../css/images/menu_line.png'
import x_btn from '../../../css/images/x_btn.png'
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useRef, useState } from 'react';

const HeaderMobile = ({targetY1, targetY2, targetY3}) => {

    // 어플소개 클릭
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }

    // 가이드 클릭
    const scrollToGuide = () => {
        window.scrollTo({
            top: targetY1,
            behavior: 'instant'
        })
    }

    // 갤러리 클릭
    const scrollToGallery = () => {
        window.scrollTo({
            top: targetY2,
            behavior: 'instant'
        })
    } 

    // 가격플랜 클릭
    const scrollToPricePlan = () => {
        window.scrollTo({
            top: targetY3,
            behavior: 'instant'
        })
    } 

    const modalRef = useRef(null);
    const imgRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [imgSelect, setImgSelect] = useState(menu_line);

    const onClickMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    useEffect(() => {
        const handleClick = (e) => {
          if (modalRef.current && !modalRef.current.contains(e.target)) {
            if (isMenuOpen) {
                $('.navbar-toggler').click()
                setIsMenuOpen(false);
            }
          }
        };
        window.addEventListener('mouseup', handleClick);
        return () => window.removeEventListener('mouseup', handleClick);
      }, [modalRef, isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen) {
            setImgSelect(x_btn);
        } else {
            setImgSelect(menu_line);
        }
    }, [isMenuOpen])

    $(document).ready(function(){
        $('.navbar-toggler').on('click', function(){
            onClickMenu();
        });
        $('.nav-item').on('click', function(){
            $('.navbar-toggler').click() //bootstrap 3.x by Richard
        });
        $('.nav-link').on('click', function(){
            $('.navbar-toggler').click() //bootstrap 3.x by Richard
        });
    });

    const onClickLogo = () => {
        window.location.reload();
    }

    return (
        <>
            <CustomNavbarNav className={"custom_navbar navbar navbar-expand-md navbar-dark " + headerCss.custom_navbar + " " + headerCss.navbar + " " + headerCss.navbar_expand_md + " " + headerCss.navbar_dark}>
                <div className={headerCss.container + " container"} style={{margin : '0px', padding : '0px'}}>
                    <NavbarBrandLink className={headerCss.navbar_brand + " navbar_brand"} to={URL.MAIN}>
                        <NavLogoImg src={logoImg} alt="logo_1" className={headerCss.nav_logo + ' nav_logo'} onClick={onClickLogo}/>
                    </NavbarBrandLink>
                    <CustomNavbarTogglerButton
                        className={headerCss.navbar_toggler + " navbar-toggler"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarsFurni"
                        aria-controls="navbarsFurni"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        collapseOnSelect
                        style={{marginRight : '20px'}}
                    >
                        <DefaultImg src={imgSelect} alt="menu_line" ref={imgRef} />
                    </CustomNavbarTogglerButton>
                    <NavbarCollapseDiv className={headerCss.collapse + " " + headerCss.navbar_collapse + " collapse navbar-collapse"} id="navbarsFurni" ref={modalRef}>
                        <CustomNavbarNavUl className={headerCss.custom_navbar_nav + " " + headerCss.navbar_nav + " " + " custom_navbar_nav navbar-nav ms-auto mb-2 mb-md-0"}>
                            <DefaultLi onClick={scrollToTop} >
                                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                                    어플소개
                                </CustomNavbarNavLiALink>
                            </DefaultLi>
                            <DefaultLi onClick={scrollToGuide} >
                                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                                    가이드
                                </CustomNavbarNavLiALink>
                            </DefaultLi>
                            <DefaultLi onClick={scrollToGallery} >
                                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                                    갤러리
                                </CustomNavbarNavLiALink>
                            </DefaultLi>
                            <DefaultLi onClick={scrollToPricePlan} >
                                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                                    가격 플랜
                                </CustomNavbarNavLiALink>
                            </DefaultLi>
                        </CustomNavbarNavUl>
                    </NavbarCollapseDiv>
                </div>
            </CustomNavbarNav>
        </>
    );
};
export default HeaderMobile;

/** li 태그 default css */
const DefaultLi = styled.li`
    height: 56px;
    z-index: 101;
    &:hover {
        background-color: #F0F0F0;
    }
`;

/** nav 태그 .custom-navbar css */
const CustomNavbarNav = styled.nav`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    background: #fff !important;
    height: 64px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
`;

/** a 태그 .navbar-brand css */
const NavbarBrandLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
    margin-left: 20px;
`;

/** img 태그 .nav-logo css */
const NavLogoImg = styled.img`
    width: 144px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    height: 24.21px;
`;

/** button 태그 .custom-navbar .navbar-toggler css */
const CustomNavbarTogglerButton = styled.button`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  border-color: transparent;
  :active {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  };
  :focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  }
  @media (max-width: 780px) {
    padding: 4px 0px;
  }
`;

/** img 태그 디폴트 css */
const DefaultImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** div 태그 .navbar-collapse css */
const NavbarCollapseDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    position: absolute;
    z-index: 99;
    background-color: #fff;
    top: 64px;
    right: 0px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
`;

/** ul 태그 .custom-navbar-nav 기본 디폴트 css  */
const CustomNavbarNavUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    z-index: 100;
`;

/** a 태그 .custom-navbar .custom-navbar-nav li a css */
const CustomNavbarNavLiALink = styled(Link)`
  text-decoration: none;
  color: #222222 !important;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
  font-family: "Noto Sans-bold";
  font-weight: 500;
  font-size: 15px;
  letter-spacing: -0.5px;
  margin: 0px 20px;
  font-display: optional;
  margin-left: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  &:hover {
    opacity: 1;
  }
  &:hover:before {
    width: calc(100% - 16px);
  }
`;