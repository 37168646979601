import styled from '@emotion/styled';
import logoImg from '../../../css/images/Logo.png'
import headerCss from '../../../css/main.module.css'
import { Link } from 'react-router-dom';
import URL from '../../constants/url';
import { useEffect, useRef, useState } from 'react';
import menu_line from '../../../css/images/menu_line.png';
import x_btn from '../../../css/images/x_btn.png'
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';

const HeaderBrowser = ({targetY1, targetY2, targetY3, setHeaderVisible}) => {

    // 어플소개 클릭
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
        setTimeout(function() {setHeaderVisible(true)}, 200);
        onClickMenu();
    }

    // 가이드 클릭
    const scrollToGuide = () => {
        window.scrollTo({
            top: targetY1,
            behavior: 'instant'
        })
        console.log(targetY1);
        setTimeout(function() {setHeaderVisible(true)}, 200);
        onClickMenu();
    }

    // 갤러리 클릭
    const scrollToGallery = () => {
        window.scrollTo({
            top: targetY2,
            behavior: 'instant'
        })
        setTimeout(function() {setHeaderVisible(true)}, 200);
        onClickMenu();
    } 

    // 가격플랜 클릭
    const scrollToPricePlan = () => {
        window.scrollTo({
            top: targetY3,
            behavior: 'instant'
        })
        setTimeout(function() {setHeaderVisible(true)}, 200);
        onClickMenu();
    } 

    const modalRef = useRef(null);
    const imgRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [imgSelect, setImgSelect] = useState(menu_line);

    const onClickMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    useEffect(() => {
        const handleClick = (e) => {
          if (modalRef.current && !modalRef.current.contains(e.target)) {
            if (isMenuOpen) {
                $('.navbar-toggler').click()
                setIsMenuOpen(false);
            }
          }
        };
        window.addEventListener('mouseup', handleClick);
        return () => window.removeEventListener('mouseup', handleClick);
      }, [modalRef, isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen) {
            setImgSelect(x_btn);
        } else {
            setImgSelect(menu_line);
        }
    }, [isMenuOpen])

    return (
        <>
            <CustomNavbarNav className={"custom_navbar navbar navbar-expand-md navbar-dark " + headerCss.custom_navbar + " " + headerCss.navbar + " " + headerCss.navbar_expand_md + " " + headerCss.navbar_dark}>
                <ContainerDiv className={headerCss.container + " container"}>
                    <NavbarBrandLink className={headerCss.navbar_brand + " navbar_brand"} to={URL.MAIN}>
                        <NavLogoImg src={logoImg} alt="logoImg" className={headerCss.nav_logo + ' nav_logo'}/>
                    </NavbarBrandLink>
                    <CustomNavbarTogglerButton
                        className={headerCss.navbar_toggler + " navbar-toggler"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarsFurni"
                        aria-controls="navbarsFurni"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        collapseOnSelect
                        style={{marginRight : '20px'}}
                    >
                        <DefaultImg src={imgSelect} alt="menu_line" ref={imgRef} />
                    </CustomNavbarTogglerButton>
                    <NavbarCollapseDiv className={headerCss.collapse + " " + headerCss.navbar_collapse + " collapse navbar-collapse"} id="navbarsFurni">
                        <CustomNavbarNavUl className={headerCss.custom_navbar_nav + " " + headerCss.navbar_nav + " " + " custom_navbar_nav navbar-nav ms-auto mb-2 mb-md-0"}>
                            <DefaultLi onClick={scrollToTop}>
                                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                                    어플소개
                                </CustomNavbarNavLiALink>
                            </DefaultLi>
                            <DefaultLi onClick={scrollToGuide}>
                                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                                    가이드
                                </CustomNavbarNavLiALink>
                            </DefaultLi>
                            <DefaultLi onClick={scrollToGallery}>
                                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                                    갤러리
                                </CustomNavbarNavLiALink>
                            </DefaultLi>
                            <DefaultLi onClick={scrollToPricePlan}>
                                <CustomNavbarNavLiALink className={headerCss.nav_link + " nav-link"}>
                                    가격 플랜
                                </CustomNavbarNavLiALink>
                            </DefaultLi>
                        </CustomNavbarNavUl>
                    </NavbarCollapseDiv>
                </ContainerDiv>
 
            </CustomNavbarNav>
        </>
    )
};
export default HeaderBrowser;

/** img 태그 디폴트 css */
const DefaultImg = styled.img`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;

/** button 태그 .custom-navbar .navbar-toggler css */
const CustomNavbarTogglerButton = styled.button`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  border-color: transparent;
  :active {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  };
  :focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  }
  @media (max-width: 780px) {
    padding: 4px 0px;
  }
`;

/** a 태그 .custom-navbar .custom-navbar-nav li a css */
const CustomNavbarNavLiALink = styled(Link)`
  text-decoration: none;
  color: #222222;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
  font-family: "Noto Sans-bold";
  font-size: 16px;
  letter-spacing: -0.5px;
  margin: 0px 20px;
  font-display: optional;
  &:before {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 8px;
    right: 8px;
    background: #ff562c;
    height: 2px;
    opacity: 1;
    visibility: visible;
    width: 0;
    color: #222222;
  }
  &:hover {
    opacity: 1;
    color : #28A8FB;
  }
  &:hover:before {
    width: 0px;
  }
  &:focus {
    color: #222222;
  }
  @media (min-width: 768px) and (max-width: 780px) {
    font-size: 12px;
  }
`;

/** li 태그 default css */
const DefaultLi = styled.li`
`;

/** div 태그 .container css */
const ContainerDiv = styled.div`
    margin: 0px 340px;
    padding: 0px 0px;
    @media (max-width: 1400px) {
        margin: 0px 100px;
    }
    @media (max-width: 768px) {
        margin: 0px 20px;
    }

`;

/** nav 태그 .custom-navbar css */
const CustomNavbarNav = styled.nav`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    background: #fff !important;
    height: 72px;
    @media (min-width: 569px) and (max-width: 768px) {
        width : 100vw;
        margin-left: auto;
        margin-right: auto;
        max-width: 768px;
    }
`;

/** a 태그 .navbar-brand css */
const NavbarBrandLink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-decoration: none;
    color: inherit;
`;

/** img 태그 .nav-logo css */
const NavLogoImg = styled.img`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: keep-all;
  height: 40px;
`;


/** div 태그 .navbar-collapse css */
const NavbarCollapseDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media (max-width: 767px) {
      position: absolute;
      z-index: 99;
      background-color: #fff;
      top: 60px;
      right: 0px;
      width: 100%;
    }
    @media (min-width: 769px) and (max-width: 780px) {
      font-size: 12px;
      width: 75%;
      top: 0px;
      right: 25%;
      padding-left: 0px;
    }
    @media (min-width: 768px) and (max-width: 768px) {
      font-size: 12px;
      width: 75%;
      top: 0px;
      right: 30%;
      padding-left: 40px;
    }
    @media (min-width: 568px) and (max-width: 768px) {
      padding-left: 10%;
      padding-right: 10%;
    }
    @media (max-width: 568px) {
      padding-left: 0px;
      padding-right: 0px;
    }
`;

/** ul 태그 .custom-navbar-nav 기본 디폴트 css  */
const CustomNavbarNavUl = styled.ul`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
`;