import HeaderMobile from "./mobileComponents/HeaderMobile";
import TopButton from "../common/TopButton";
import MainComponentMobile from "./mobileComponents/MainComponentMobile";
import '../../css/mobile.css'
import FooterMobile from "./mobileComponents/FooterMobile";
import { useEffect, useState } from "react";

const TenSecondsMobile = () => {

    const start = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }

    useEffect(() => {
        start();
        setAllLoaded(false);
        setLoadedCount(0);
    }, []);

    const [targetY1, setTargetY1] = useState(1250);
    const [targetY2, setTargetY2] = useState(4100);
    const [targetY3, setTargetY3] = useState(4800);

    const [loadedCount, setLoadedCount] = useState(0);
    const [allLoaded, setAllLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoadedCount(prevCount => prevCount + 1);
    };

    useEffect(() => {
        if (loadedCount === 6) {
            setAllLoaded(true);
        }
    }, [loadedCount])

    return (
        <>
            <TopButton/>
            <HeaderMobile targetY1={targetY1} targetY2={targetY2} targetY3={targetY3}/>
            <MainComponentMobile setTargetY1={setTargetY1} setTargetY2={setTargetY2} setTargetY3={setTargetY3} handleImageLoad={handleImageLoad} allLoaded={allLoaded}/>
            <FooterMobile/>
        </>
    );
};
export default TenSecondsMobile;
