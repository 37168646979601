import { Route, Routes } from "react-router"
import URL from "../constants/url"
import TenSecondsMain from "../pages/TensecondsMain";


const RootRoutes = () => {

    return (
        <Routes>
            <Route path={URL.MAIN} element={<TenSecondsMain/>}/>
        </Routes>
    )
}
export default RootRoutes;