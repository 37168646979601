import img_icon_1 from '../../../css/images/img_icon_mobile_1.png';
import img_icon_2 from '../../../css/images/img_icon_mobile_2.png';
import img_icon_3 from '../../../css/images/img_icon_mobile_3.png';
import text_icon from '../../../css/images/text_img_mobile_1.png';
import text_icon_2 from '../../../css/images/text_img_mobile_2.png';
import text_icon_3 from '../../../css/images/text_img_mobile_3.png';
import text_last from '../../../css/images/text_icon_img_last.png';
import main_mobile_img_5 from '../../../css/images/main_mobile_img_5.png';
import mainCss from '../../../css/main_mobile.module.css'
import styled from '@emotion/styled';
import URL from '../../constants/url';
import { useEffect, useRef, useState } from 'react';


const MainImg2 = ({setTargetY3, handleImageLoad, allLoaded}) => {

    const onClickBtn = () => {
        let pageUrl = URL.GOOGLE_APP;
        //let pageUrl = URL.MAIN;
        window.location.href = pageUrl;
    }

    const targetRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setTargetY3(targetRef.current.offsetTop - 64);
        }, 1000)
    }, []);

    // 화면 넓이를 저장할 상태 변수
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // 화면 넓이 변경을 감지하는 함수
    const handleResize = () => {
        setWidth(window.innerWidth);  // 현재 창의 넓이로 상태 업데이트
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);  // resize 이벤트 리스너 등록

        return () => {
        window.removeEventListener('resize', handleResize);  // 컴포넌트 언마운트 시 리스너 제거
        };
    }, []);  // 빈 의존성 배열로 마운트 시 한 번만 실행

    useEffect(() => {
        if (allLoaded) {
            setTargetY3(targetRef.current.offsetTop - 64);
        }
    }, [allLoaded])
    
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (loaded) {
            setTargetY3(targetRef.current.offsetTop - 64);
        }
    }, [width, height, loaded])
    useEffect(() => {
        const img = new Image();
        img.src = main_mobile_img_5;
        img.onload = () => {
          console.log('Image fully loaded!');
          setLoaded(true);
        };
    }, []);

    return (
        <>
            <ContainerDiv className={mainCss.container + " container"} ref={targetRef}>
                <TextImg1 src={main_mobile_img_5} onLoad={handleImageLoad}/>
                <ColBoxDiv>
                    <ColDiv>
                        <img src={img_icon_1} style={{float : 'left', marginLeft : '20px', marginTop : '-10px', padding : '0px'}}/>
                        <TextIconImg src={text_icon} />
                        <BtnDiv onClick={onClickBtn}>
                            <PriceTabP style={{fontWeight : '700', fontSize : '18px', color : '#fff', position : 'relative', top : '13px'}}>바로 시작하기</PriceTabP>
                        </BtnDiv>
                    </ColDiv>
                    {/*<ColDiv >
                        <img src={img_icon_2} style={{float : 'left', marginLeft : '20px', marginTop : '-10px' , padding : '0px'}}/>
                        <TextIconImg src={text_icon_2} />
                        <BtnDiv onClick={onClickBtn}>
                            <PriceTabP style={{fontWeight : '700', fontSize : '18px', color : '#fff', position : 'relative', top : '13px'}}>바로 시작하기</PriceTabP>
                        </BtnDiv>
                    </ColDiv>*/}
                    <ColDiv >
                        <img src={img_icon_3} style={{float : 'left', marginLeft : '20px', marginTop : '-10px' , padding : '0px'}}/>
                        <TextIconImg src={text_icon_3} />
                        <BtnDiv onClick={onClickBtn}>
                            <PriceTabP style={{fontWeight : '700', fontSize : '18px', color : '#fff', position : 'relative', top : '13px'}}>바로 시작하기</PriceTabP>
                        </BtnDiv>
                    </ColDiv>
                </ColBoxDiv>
                <div style={{height : '40px'}}></div>
                <TextImg2 src={text_last}/>
                <div style={{height : '40px'}}></div>
            </ContainerDiv>
        </>
    );
};
export default MainImg2;

const TextImg2 = styled.img`
    width: 90vw;
    height: auto;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
`;

const TextImg1 = styled.img`
    width: 90vw;
    height: auto;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    margin-bottom: 20px;
    @media (max-width: 320px) {
        width: 90vw;
        height: auto;
    }
`;

const TextIconImg = styled.img`
    position: static;
    margin-right: 74px;
    margin-left: 74px;
    margin-top: 0px;
    margin-bottom: 20px;
    @media (max-width: 321px) {
        margin-right: 10%;
        margin-left: 10%;
        margin-top: 0px;
        margin-bottom: 20px;
    }
`;

/** div 태그 .container css */
const ContainerDiv = styled.div`
    width: 100vw;
    height: auto;
    margin: 0px 0px;
    padding: 0px 0px;
    background: #BDE4FE;
    text-align: center;
`;

const ColBoxDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    gap: 24px;
    justify-content: center;
    align-items : center;
`;

const ColDiv = styled.div`
    width: 90vw;
    height: 320px;
    background-color: #fff;
    border-radius: 30px;
    
`;

const TitleBox = styled.div`
    justify-content: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    align-self: center;
    margin-top: 50px;
`;

const TitleText = styled.p`
    text-align: left;
    font-family: 'Pretendard';
    font-size: 22px;
    color: #222222;
    font-weight: 500;
    margin-bottom: 0px;
    position: relative;
    z-index: 80;
`;

/** div 태그 .priceTab css */
const PriceTabDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0px;
`;

/** p 태그 .priceTab p css */
const PriceTabP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 22px;
    font-family: "Pretendard";
    color: #868D93;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
`;

/** span 태그 .money css */
const MoneySpan = styled.span`
    margin: 0;
    padding: 0;
    word-break: keep-all;
    font-size: 36px;
    font-family: "Pretendard";
    color: #222222;
    letter-spacing: -0.3px;
    font-weight: 700;
    position: relative;
    top: -10px;
    text-align: center;
`;

/** span 태그 .won css */
const WonSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 16px;
    font-family: "Pretendard";
    color: #868D93;
    letter-spacing: -0.3px;
    text-align: center;
    font-weight: 700;
`;

const BtnDiv = styled.div`
    width: 200px;
    height: 54px;
    border-radius: 30px;
    background-color: #31A2FF;
    margin: auto;
    cursor: pointer;
`;