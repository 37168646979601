import './App.css';
import { RecoilRoot } from 'recoil';
import { CookiesProvider } from 'react-cookie';
import RootRoutes from './componnents/routes';

function App() {
  return (
    <RecoilRoot>
      <CookiesProvider>
          <RootRoutes />
      </CookiesProvider>
    </RecoilRoot>
  );
}

export default App;
