import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../css/images/footer_logo_img_mobile.png';
import styled from '@emotion/styled';
import footerCss from '../../../css/main.module.css';
import URL from '../../constants/url';


// 하단부 footer
const FooterMobile = () => {

  const navigate = useNavigate();

  // 버튼 type에 따른 url 이동
  const onClickBtn = (type) => {
    if (type === 1) {
        let pageUrl = URL.TERM_IN_PERSONAL_INFORMATION;
        window.location.href = pageUrl;
    } else if (type === 2) {
        let pageUrl = URL.TERM_IN_TERMS_SERVICE;
        window.location.href = pageUrl;
    } 
  }

  // 로고 클릭
  const onClickLogo = () => {
    navigate(URL.MAIN);
  }

  return (
    <>
      <ContainerDDiv>
        <ContainerDiv className={footerCss.container + " container"}>
          <FooterSections>
            <LogoDiv onClick={onClickLogo} >
              <FooterImg src={logo} alt="logo-d" />
            </LogoDiv>
            <FooterText1Div>
              (주)내스타일 제공
            </FooterText1Div>
            <FooterText2Div style={{marginBottom : '4px'}}>
              주식회사 내스타일 
            </FooterText2Div>
            <FooterText2Div style={{marginBottom : '4px'}}>
              대표자: 이용균 | 사업자번호: 382-81-01860
            </FooterText2Div>
            <FooterText2Div style={{marginBottom : '4px'}}>
              통신판매업 신고번호: 제 2022-서울서초-1973호
            </FooterText2Div>
            <FooterText2Div style={{marginBottom : '4px'}}>
              이메일: contact@nestyle.ai | 전화: 02-2155-2040
            </FooterText2Div>
            <FooterText2Div style={{marginBottom : '8px'}}>
              주소: 서울 서초구 매헌로 16, 1201호
            </FooterText2Div>
            <FooterText3Div className="d-flex">
              <div onClick={() => {onClickBtn(2)}}><FooterText3Link >서비스 이용약관</FooterText3Link></div> 
              <div onClick={() => {onClickBtn(1)}}><FooterText3Link>개인정보 처리방침</FooterText3Link></div>
            </FooterText3Div>
          </FooterSections>
        </ContainerDiv>
      </ContainerDDiv>
    </>
  );
};

export default FooterMobile;

/**div 태그 로고 css */
const LogoDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

/** div 태그 .container css */
const ContainerDiv = styled.div`
    width: 100vw;
    height: auto;
    padding: 0;
    overflow-x: hidden;
`;

/** a 태그 디폴트 css */
const FooterText3Link = styled(Link)`
  color: #050409;
  text-decoration: none;
  margin-right: 22px;
  font-size: 12px;;
`;

/** div 태그 .container-l css  */
const ContainerDDiv = styled.div`
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
`;

/** section 태그 .footer css */
const FooterSections = styled.section`
  padding: 11.1%;
  margin: 0 auto;
`;

/** img 태그 .footer-img css */
const FooterImg = styled.img`
  height: 25px;
  width: auto;
  margin-bottom: 20px;
  cursor: pointer;
`;

/** div 태그 .footer-text-1 css */
const FooterText1Div = styled.div`
  font-family: "Min Sans-Regular";
  font-size: 12px;
  font-weight: 400;
  color: #050409;
  letter-spacing: -0.3px;
  margin-bottom: 8px;
`;

/** div 태그 .footer-text-1 css */
const FooterText2Div = styled.div`
  font-family: "Min Sans-Regular";
  font-size: 11px;
  font-weight: 400;
  color: #828284;
  letter-spacing: -0.3px;
`;

/** div 태그 .footer-text-1 css */
const FooterText3Div = styled.div`
  display: flex;
  margin-top: 8px;
  font-family: "Min Sans-Regular";
  letter-spacing: -0.3px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 40px;
`;

