import mainCss from '../../../css/main.module.css'
import styled from '@emotion/styled';
import textImg1 from '../../../css/images/text_img_1.png';
import textImg2 from '../../../css/images/text_img_2.png';
import googleBtn from '../../../css/images/google_btn.png';
import appleBtn from '../../../css/images/apple_btn.png';
import mainImg3 from '../../../css/images/main_img_3.png';
import mainImg4 from '../../../css/images/main_img_4.png';
import URL from '../../constants/url';
import { useEffect, useRef, useState } from 'react';

const Main2Img = ({setTargetY1, setTargetY2, handleImageLoad, allLoaded}) => {
    
    const openGoogle = () => {
        let pageUrl = URL.GOOGLE_APP;
        //let pageUrl = URL.MAIN;
        window.location.href = pageUrl;
    }

    const openApple = () => {
        let pageUrl = URL.APPLE_APP;
        //let pageUrl = URL.MAIN;
        window.location.href = pageUrl;
    }

    const target1Ref = useRef(null);
    const target2Ref = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setTargetY1(target1Ref.current.offsetTop);
            setTargetY2(target2Ref.current.offsetTop);
        }, 500)
    }, []);

    // 화면 넓이를 저장할 상태 변수
    const [width, setWidth] = useState(window.innerWidth);

    // 화면 넓이 변경을 감지하는 함수
    const handleResize = () => {
        setWidth(window.innerWidth);  // 현재 창의 넓이로 상태 업데이트
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);  // resize 이벤트 리스너 등록

        return () => {
        window.removeEventListener('resize', handleResize);  // 컴포넌트 언마운트 시 리스너 제거
        };
    }, []);  // 빈 의존성 배열로 마운트 시 한 번만 실행

    useEffect(() => {
        if (allLoaded) {
            setTargetY1(target1Ref.current.offsetTop);
            setTargetY2(target2Ref.current.offsetTop);
        }
    }, [allLoaded])
    useEffect(() => {
        if (allLoaded) {
            setTargetY1(target1Ref.current.offsetTop);
            setTargetY2(target2Ref.current.offsetTop);
        }
    }, [width])

    return (
        <>
            <ContainerDiv className={mainCss.container + " container"}>
                <TextImg1 src={textImg1} onLoad={handleImageLoad}/>
                <TextImgBoxDiv>
                    <TextImg2 src={textImg2} onLoad={handleImageLoad}/>
                    <Btn_img src={googleBtn} onClick={openGoogle} onLoad={handleImageLoad}/>
                    <Btn_img src={appleBtn} style={{marginLeft : '12px'}} onClick={openApple} onLoad={handleImageLoad}/>
                </TextImgBoxDiv>
                <MainImg3Img src={mainImg3} ref={target1Ref} onLoad={handleImageLoad}/>
            </ContainerDiv>
            <MainImg4Img src={mainImg4} ref={target2Ref} onLoad={handleImageLoad}/>
        </>
    );
};
export default Main2Img;

/** div 태그 .container css */
const ContainerDiv = styled.div`
    width: 100vw;
    height: auto;
    margin: 0px 0px;
    padding: 0px 0px;
    max-width: 1900px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 10%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 0.95) 40%,
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0.85) 75%,
        rgba(255, 255, 255, 0.8) 100%
    ), #009BFF;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items : center;
`;

const TextImg1 = styled.img`
    width: 470px;
    height: 144px;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 88px;
    @media (max-width: 992px) {
        margin-left: 20%;
        margin-right: 20%;
    }
`;

const TextImgBoxDiv = styled.div`
    width: 460px;
    height: 395px;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 29px;
    @media (max-width: 992px) {
        margin-left: 15%;
        margin-right: 15%;
    }
`;

const TextImg2 = styled.img`
    width: 460px;
    height: 325px;
`;

const Btn_img = styled.img`
    width: 224px;
    height: 70px;
    cursor: pointer;
`;

const MainImg3Img = styled.img`
    width: 60.15vw;
    height: auto;
    margin-top: 100px;
    margin-left: 15%;
    margin-right: 15%;
`;

const MainImg4Img = styled.img`
    width: 100vw;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    
`;